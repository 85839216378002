class ConfigurationManager {
  static get userRoles() {
    return class {
      static get administrators() {
        return 'Administrators';
      }
      static get wibUsers() {
        return 'WibUsers';
      }

      static get viceversaUsers() {
        return 'ViceversaUsers';
      }
    }
  }

  static get users() {

    return class {
      static get viceversa() {
        return "viceversa";
      }
      static get kristianScipione() {
        return "kristian.scipione";
      }
      static get federicoPastore() {
        return "federico.pastore@gomme-auto.com";
      }
      static get backoffice() {
        return "backoffice";
      }
      static get amministrazione() {
        return "amministrazione";
      }
      static get barbara() {
        return "barbara";
      }
    }

  }
}

export default ConfigurationManager;