import React, { useEffect, useState } from 'react';
import { Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem, NavLink, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CookieManager from '../CookieManager';

const NavMenu = ({ handleLogout }) => {
  const [collapsed, setCollapsed] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [user, setUser] = useState({});

  const toggleNavbar = () => setCollapsed(!collapsed);
  const toggle = () => setDropdownOpen(!dropdownOpen);

  useEffect(() => {
    const token = CookieManager.get();
    if (token) {
      const decodedToken = CookieManager.decode();
      setUser({ userName: decodedToken.userName });
    }
  }, []);

  return (
    <header>
      <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
        <NavbarBrand tag={Link} to="/">
          <FontAwesomeIcon icon="gears" />
          Stats</NavbarBrand>
        <NavbarToggler onClick={toggleNavbar} className="mr-2" />
        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink href="#">
                <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                  <DropdownToggle caret>
                    <FontAwesomeIcon icon="user" />
                    {user.userName}
                  </DropdownToggle>
                  <DropdownMenu end>
                    {/*<DropdownItem header>Logged in as {user.userName}</DropdownItem>*/}
                    {/*<DropdownItem divider />*/}
                    <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </header>
  );
};

export default NavMenu;
