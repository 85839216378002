import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CookieManager from '../../CookieManager';
import DataManager from '../../DataManager';

const FromDateToDateStat = ({ caption, users, roles, endpoint, handleSubmit, maxDays, stored, fileName }) => {
  //const yesterday = new Date(Date.now() - ConfigurationManager.oneDayMs); // 86400000 ms = 1 day
  const yesterday = DataManager.date.addDays(Date.now(), -1);
  //const fromDateDefault = new Date(yesterday.getTime() - maxDays * ConfigurationManager.oneDayMs)
  const fromDateDefault = DataManager.date.addDays(yesterday, -maxDays);
  const toDateDefault = yesterday; // Set to yesterday

  const [fromDate, setFromDate] = useState(fromDateDefault);
  const [toDate, setToDate] = useState(toDateDefault);
  const [lastChangedDate, setLastChangedDate] = useState(null);
  const [dateRangeError, setDateRangeError] = useState(false);

  //useEffect(() => {
  //  // check if the last changed date is fromDate or toDate
  //  // and calculate the corresponding valid date range
  //  if (lastChangedDate) {
  //    if (lastChangedDate === 'fromDate') {
  //      //const maxToDate = new Date(new Date(fromDate).getTime() + maxDays * ConfigurationManager.oneDayMs)
  //      const maxToDate = DataManager.date.addDays(fromDate, maxDays);
  //      if (toDate > maxToDate) {
  //        setToDate(maxToDate);
  //      }
  //      if (toDate < fromDate) {
  //        setToDate(fromDate);
  //      }
  //    } else if (lastChangedDate === 'toDate') {
  //      //const minFromDate = new Date(new Date(toDate).getTime() - maxDays * ConfigurationManager.oneDayMs)
  //      const minFromDate = DataManager.date.addDays(toDate, -maxDays);
  //      if (fromDate < minFromDate) {
  //        setFromDate(minFromDate);
  //      }
  //      if (fromDate > toDate) {
  //        setFromDate(toDate);
  //      }
  //    }
  //  }
  //}, [fromDate, toDate, lastChangedDate]);

  useEffect(() => {
    setDateRangeError(false);
    // check if the selected date range is greater than 30 days
    const timeDiff = Math.abs(new Date(toDate) - new Date(fromDate));
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    if (diffDays > maxDays) {
      setDateRangeError(true);
    } 
  }, [fromDate, toDate]);

  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
    setLastChangedDate('fromDate');
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
    setLastChangedDate('toDate');
  };

  const doSubmit = (e) => {
    e.preventDefault();
    let payload = { fromDate: fromDate, toDate: toDate, maxDays: maxDays, stored: stored, fileName: fileName };
    handleSubmit(e, endpoint, payload);
  }

  const errorMessage = dateRangeError === false ? null :
    <h5 className="card-title text-danger">Invalid date range</h5>;

  if (CookieManager.isInUsers(users) || CookieManager.isInRoles(roles)) {
    return (
      //<form onSubmit={(e) => handleSubmit(e, endpoint, payload)} className="container my-5">
      <form onSubmit={doSubmit} className="container my-5">
        <div className="card p-3">
          <h5 className="card-title">{caption} (max delta = {maxDays} days)</h5>
          {errorMessage}
          <div className="row align-items-end">
            <div className="col-md-4 mb-3">
              <label htmlFor="fromDate" className="form-label">
                Start Date
              </label>
              <input
                type="date"
                className="form-control"
                id="fromDate"
                value={fromDate}
                //max={toDate}
                min="2000-01-01"
                max="2099-12-31"
                onChange={handleFromDateChange}
              />
            </div>
            <div className="col-md-4 mb-3">
              <label htmlFor="toDate" className="form-label">
                End Date
              </label>
              <input
                type="date"
                className="form-control"
                id="toDate"
                value={toDate}
                min="2000-01-01"
                max="2099-12-31"
                onChange={handleToDateChange}
              />
            </div>
            <div className="col-md-4  mb-3 text-end">
              <button type="submit" className="btn btn-primary">
                <FontAwesomeIcon icon="download" />
                Download File
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  } else {
    return null;
  }
};

export default FromDateToDateStat;