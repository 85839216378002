import React from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';

const Layout = ({ children, isLoggedIn, handleLogout }) => {
  return (
    <div>
      {isLoggedIn && <NavMenu handleLogout={handleLogout} />}
      <Container tag="main">
        {children}
      </Container>
    </div>
  );
}
export default Layout;
