import React, { useEffect, useState } from 'react';
import FromDateToDateStat from './stats/FromDateToDateStat';
import LoadingModal from './LoadingModal';
import CookieManager from '../CookieManager';
import ConfigurationManager from '../ConfigurationManager';

const Home = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    // Read the login state from local storage, default to false if not present
    return JSON.parse(localStorage.getItem('isLoggedIn')) || false;
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isLoggedIn) {
      // Store the login state in local storage
      CookieManager.remove();
      localStorage.setItem('isLoggedIn', false);
    }
  }, [isLoggedIn]);

  const handleSubmit = async (e, endpoint, payload) => {
    e.preventDefault();
    try {
      const token = CookieManager.get();
      let response;
      const requestInit = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(payload)
      };
      setIsLoading(true);
      await fetch(endpoint, requestInit)
        .then((responseData) => {
          if (responseData.status === 401) {
            // handle unauthorized error
            console.error('Unauthorized');
            //Cookies.remove('jwtToken');
            //localStorage.setItem('isLoggedIn', 'false');
            setIsLoggedIn(false);
          }
          response = responseData;
          setIsLoading(false);
        })
        .catch((error) => {
          //if (error.message === 'Unauthorized') {
          //  // handle unauthorized error
          //  console.error('Unauthorized');
          //  Cookies.remove('jwtToken');
          //  localStorage.setItem('loggedIn', 'false');
          //} else {
          console.error(error);
          setIsLoading(false);
          //}
        });
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', response.headers.get('Content-Disposition')?.split(';')[1].replace(' filename=', ''));
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      {isLoading && < LoadingModal />}
      <FromDateToDateStat roles={[ConfigurationManager.userRoles.administrators]} caption='Nts Shipped Documents' maxDays='180' stored='SpStatsNtsShippedDocuments' fileName='NtsShippedDocuments' endpoint='/stats/fromdate-todate' handleSubmit={handleSubmit} />
      <FromDateToDateStat roles={[ConfigurationManager.userRoles.administrators]} caption='Report Michelin Mensile' maxDays='120' stored='SpStatsMichelinMonthlyReport' fileName='MichelinMonthlyReport' endpoint='/stats/fromdate-todate' handleSubmit={handleSubmit} />
      <FromDateToDateStat roles={[ConfigurationManager.userRoles.administrators]} caption='Report Michelin Mensile x Pattern' maxDays='120' stored='SpStatsMichelinMonthlyReport_GroupByPattern' fileName='MichelinMonthlyReport_GroupByPattern' endpoint='/stats/fromdate-todate' handleSubmit={handleSubmit} />
      <FromDateToDateStat users={[ConfigurationManager.users.amministrazione]} roles={[ConfigurationManager.userRoles.administrators]} caption='StreetFood Daily Report' maxDays='30' stored='SpStatsStreetFoodDailyReport' fileName='StreetFoodDailyReport' endpoint='/stats/fromdate-todate' handleSubmit={handleSubmit} />
      <FromDateToDateStat users={[ConfigurationManager.users.backoffice]} roles={[ConfigurationManager.userRoles.administrators]} caption='Distributor Rules' maxDays='120' stored='SpStatsDistributorRules' fileName='DistributorRules' endpoint='/stats/fromdate-todate' handleSubmit={handleSubmit} />
      <FromDateToDateStat users={[ConfigurationManager.users.backoffice]} roles={[ConfigurationManager.userRoles.administrators]} caption='Edock Products' maxDays='120' stored='SpStatsEdockProducts' fileName='EdockProducts' endpoint='/stats/fromdate-todate' handleSubmit={handleSubmit} />
      <FromDateToDateStat users={[ConfigurationManager.users.backoffice]} roles={[ConfigurationManager.userRoles.administrators]} caption='Marco Vehicle KTypes' maxDays='120' stored='SpStatsMarcoVehicleKTypes' fileName='MarcoVehicleKTypes' endpoint='/stats/fromdate-todate' handleSubmit={handleSubmit} />
      <FromDateToDateStat users={[ConfigurationManager.users.backoffice]} roles={[ConfigurationManager.userRoles.administrators]} caption='TopRuote Orders Print Queue' maxDays='10' stored='SpStatsTopRuoteOrdersPrintQueue' fileName='TopRuoteOrdersPrintQueue' endpoint='/stats/fromdate-todate' handleSubmit={handleSubmit} />
      <FromDateToDateStat users={[ConfigurationManager.users.backoffice]} roles={[ConfigurationManager.userRoles.administrators]} caption='Top Ruote Not Invoiced Orders' maxDays='120' stored='SpStatsTopRuoteNotInvoicedOrders' fileName='TopRuoteNotInvoicedOrders' endpoint='/stats/fromdate-todate' handleSubmit={handleSubmit} />
      <FromDateToDateStat users={[ConfigurationManager.users.federicoPastore]} roles={[ConfigurationManager.userRoles.administrators]} caption='Newsletter Cg' maxDays='120' stored='SpStatsSpDemCustomersCg' fileName='SpStatsSpDemCustomersCg' endpoint='/stats/fromdate-todate' handleSubmit={handleSubmit} />
      <FromDateToDateStat users={[ConfigurationManager.users.federicoPastore]} roles={[ConfigurationManager.userRoles.administrators]} caption='Newsletter Ga' maxDays='120' stored='SpStatsSpDemCustomersGa' fileName='SpStatsSpDemCustomersCg' endpoint='/stats/fromdate-todate' handleSubmit={handleSubmit} />
      <FromDateToDateStat users={[ConfigurationManager.users.federicoPastore, ConfigurationManager.users.kristianScipione]} roles={[ConfigurationManager.userRoles.administrators]} caption='Wib Analytics Orders Stats' maxDays='31' endpoint='/wib/orders' handleSubmit={handleSubmit} />
      <FromDateToDateStat users={[ConfigurationManager.users.federicoPastore]} roles={[ConfigurationManager.userRoles.administrators]} caption='Analytics inconsistent margins' maxDays='365' stored='SpStatsAnalyticsInconsistentMargins' fileName='AnalyticsInconsistentMargins' endpoint='/stats/fromdate-todate' handleSubmit={handleSubmit} />
      <FromDateToDateStat users={[ConfigurationManager.users.federicoPastore]} roles={[ConfigurationManager.userRoles.administrators]} caption='Michelin Good Report' maxDays='120' stored='SpStatsMichelinGoodReport' fileName='MichelinGoodReport' endpoint='/stats/fromdate-todate' handleSubmit={handleSubmit} />
      <FromDateToDateStat users={[ConfigurationManager.users.barbara]} roles={[ConfigurationManager.userRoles.administrators]} caption='Pfu Export' maxDays='90' stored='SpStatsExportPfuEuOrders' fileName='PfuExport' endpoint='/stats/fromdate-todate' handleSubmit={handleSubmit} />
      <FromDateToDateStat users={[ConfigurationManager.users.viceversa]} roles={[ConfigurationManager.userRoles.administrators]} caption='Michelin Good Report' maxDays='120' stored='SpStatsMichelinGoodReport' fileName='MichelinGoodReport' endpoint='/stats/fromdate-todate' handleSubmit={handleSubmit} />
    </div>
  );
};

export default Home;