import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import Layout from './components/Layout';
import LoginForm from './components/LoginForm';
import './custom.css';
import CookieManager from './CookieManager';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    // Read the login state from local storage, default to false if not present
    return JSON.parse(localStorage.getItem('isLoggedIn')) || false;
  });
  const [loginErrorMessage, setLoginErrorMessage] = useState('');
  useEffect(() => {
    const token = CookieManager.get();
    if (token) {
      // TODO: send token validation request to the server
      setIsLoggedIn(true);
      // Store the login state in local storage
      localStorage.setItem('isLoggedIn', true);
    }
  }, []);

  const location = useLocation();
  const navigate = useNavigate();
  
  const handleLogin = async (username, password) => {
    try {
      const response = await fetch('auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username, password })
      });

      if (response.ok) {
        setLoginErrorMessage('');
        const data = await response.json();
        CookieManager.set(data);
        setIsLoggedIn(true);
        // Store the login state in local storage
        localStorage.setItem('isLoggedIn', true);
        // Redirect user to desired page after logging in
        const { from } = location.state || { from: { pathname: '/' } };
        navigate(from);
      } else {
        setLoginErrorMessage(response.status + ' - ' + response.statusText);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleLogout = () => {
    CookieManager.remove();
    setIsLoggedIn(false);
    // Remove the login state from local storage
    localStorage.removeItem('isLoggedIn');
  };

  return (
    <Layout isLoggedIn={isLoggedIn} handleLogout={handleLogout}>
      <Routes>
        {AppRoutes.map((route, index) => {
          const { element, path, isPrivate } = route;
          const GuardedRoute = isPrivate ? PrivateRoute : Route;
          return (
            <GuardedRoute
              key={index}
              path={path}
              element={isLoggedIn ? element : <Navigate to="/login" />}
            />
          );
        })}
        <Route path="/login" element={<LoginForm errorMessage={loginErrorMessage} handleLogin={handleLogin} />} />
        <Route path="/logout" element={<Navigate to="/" />} />
      </Routes>
    </Layout>

  );
};

const PrivateRoute = ({ element, ...rest }) => {
  const isLoggedIn = CookieManager.get();
  return isLoggedIn ? <Route {...rest} element={element} /> : <Navigate to="/login" />;
};

export default App;