import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LoginForm = ({ errorMessage, handleLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const storedIsLoggedIn = localStorage.getItem('isLoggedIn');
    setIsLoggedIn(storedIsLoggedIn === 'true' ? true : false);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleLogin(username, password);
  };

  return (
    <div className="container d-flex justify-content-center align-items-center vh-100">
      <div className="bg-light border rounded p-3" style={{ width: '30%' }}>
        <form onSubmit={handleSubmit}>
          {errorMessage && (
            <label className="text-danger mb-3">{errorMessage}</label> // display error message
          )}
          <div className="form-group mb-3">
            <input
              type="text"
              className="form-control"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Username"
            />
          </div>
          <div className="form-group mb-3">
            <input
              type="password"
              className="form-control"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />
          </div>
          <div className="form-group d-flex justify-content-end">
            <button type="submit" className="btn btn-primary btn-sm" style={{ width: 'auto' }}>
              <FontAwesomeIcon icon="user" />
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
