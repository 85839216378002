import React, { useEffect, useState } from 'react';

const LoadingModal = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 750); // delay in milliseconds
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {show && (
        <div>
          <div className="modal fade show" tabIndex="-1" role="dialog" aria-labelledby="loadingModalLabel" aria-hidden="true" style={{ display: "block" }}>
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content bg-transparent border-0">
                <div className="modal-body">
                  <div className="d-flex justify-content-center align-items-center">
                    <img src="/images/loading.gif" alt="Loading" className="img-fluid w-100" style={{ maxWidth: "300px" }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show"></div>
        </div>
      )}
    </>
  );
};

export default LoadingModal;
