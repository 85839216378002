import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUser, faDownload, faGears } from '@fortawesome/free-solid-svg-icons';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

library.add(faUser);
library.add(faDownload);
library.add(faGears);


root.render(
    <BrowserRouter basename={baseUrl}>
      <App />
    </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
