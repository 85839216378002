class DataManager {

  static get date() {
    return class {
      static get oneDayMs() {
        return 86400000;
      }
      static addDays(fromDate, days) {
        if (!fromDate || fromDate == '') {
          fromDate = '1900-01-01';
        }
        return new Date(new Date(fromDate).getTime() + days * this.oneDayMs)
          .toISOString()
          .substring(0, 10); // Subtract maxDays from yesterday
      }
    }
  }
}

export default DataManager;