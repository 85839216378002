import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';

class CookieManager {
  static get jwtToken() {
    return 'jwtToken';
  }

  static redirectToLogin() {
    window.location.href = '/login';
  }

  static set(data) {
    const token = data.token;
    const expiration = new Date(data.expireOn);
    Cookies.set(CookieManager.jwtToken, token, { expires: expiration, sameSite: 'strict' });
  }

  static get() {
    try {
      return Cookies.get(CookieManager.jwtToken);
    }
    catch
    {
      CookieManager.redirectToLogin();
    }
  }

  static remove() {
    Cookies.remove(CookieManager.jwtToken);
  }

  static decode() {
    try {
      let token = CookieManager.get();
      return jwt_decode(token.substring(`${CookieManager.jwtToken}=`.length));
    }
    catch
    {
      CookieManager.redirectToLogin();
    }
  }

  static isInRoles(roles) {
    try {
      let userRoles = CookieManager.decode().roles?.split(",");
      return roles.some(element => userRoles?.includes(element));
    }
    catch
    {
      CookieManager.redirectToLogin();
    }
  }

  static isInUsers(users) {
    try {
      let userName = CookieManager.decode().userName;
      //return users?.some(element => userName === element);
      return users?.includes(userName);
    }
    catch
    {
      CookieManager.redirectToLogin();
    }
  }
}

export default CookieManager;